<template>
  <main id="main">
    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero">
        <div class="container">
          <div class="breadcrumb-hero">
            <h2>Contact</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <ol>
          <li><router-link :to="{ name: 'home' }">Home</router-link></li>
          <li>Contact</li>
        </ol>
      </div>
    </section><!-- End Breadcrumbs -->

  <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container">

        <div class="row">

          <div class="col-lg-4">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Quarry Works:</h4>
                <p>237 Mt Marrow Quarry Rd, Mt Marrow, QLD 4306</p>
              </div>

              <div class="post-address">
                <i class="bi bi-geo-alt"></i>
                <h4>Postal Address:</h4>
                <p>
                  237 Mt Marrow Quarry Rd<br/>
                  Mt Marrow via Haigslea<br/>
                  QLD 4306
                </p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>
                  <label>Sales:</label> sales@mtmarrow.com
                </p>
                <p>
                  <label>Accounts:</label> accounts@mtmarrow.com
                </p>
                <p>
                  <label>Laboratory:</label> lab@mtmarrow.com
                </p>
                <p>
                  <label>Op. Manager:</label> sse@mtmarrow.com
                </p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <p class="pb-2">(07) 5464 4644</p>
                <p>
                  <b>Extensions:</b>
                  <ol>
                      <li>Weighbridge & Despatch</li>
                      <li>Sales</li>
                      <li>Operations Manager</li>
                      <li>Accounts</li>
                      <li>Laboratory & Quality Assurance</li>
                  </ol>
                </p>
              </div>

            </div>

          </div>

        </div>

      </div>
    </section><!-- End Contact Section -->
    <!-- ======= Account Application Section ======= -->
    <section id="account-app" class="faq section-bg">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Account Applications</h2>
        </div>
        <div>
          <p>
            Customers who wish to apply for a credit account can find the necessary documents below.<br/>
            <br/>
            Please make contact with Accounts before submitting your application.<br/>
          </p>
          <ul>
            <li>
              <a href="/docs/Mt_Marrow_Terms_of_Sale.pdf">Terms of Sale</a>
            </li>
            <li>
              <a href="/docs/Mt_Marrow_Credit_Application.pdf">Application for Commercial Credit</a>
            </li>
            <li>
              <a href="/docs/Mt_Marrow_Credit_Reporting_Policy.pdf">Credit Reporting Policy</a>
            </li>
            <li>
              <a href="/docs/Mt_Marrow_Notifiable_Matters_Statement.pdf">Statement of Notifiable Matters</a>
            </li>
            <li>
              <a href="/docs/Mt_Marrow_Collection_Notice.pdf">Information Collection Notice</a>
            </li>
            <li>
              <a href="/docs/Mt_Marrow_Privacy_Policy.pdf">Privacy Policy</a><br>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>
